<template>
  <div class="content">
    <h3 class="text-center">{{ksmc}}审核</h3>
    <person ref="person" :ksbmbh="ksbmbh" :bmh="bmh" :isPhoto="isPhoto" :type="type" :checkType="checkType"></person>
  </div>
</template>

<script>
import person from "./person";
export default {
  name: "check-new-win",
  components:{
    person
  },
  data(){
    return {
      ksmc: '',
      ksbmbh: this.$route.query.ksbmbh,
      bmh: this.$route.query.bmh,
      isPhoto: this.$route.query.isPhoto == 'true',
      type: this.$route.query.type,
      checkType: this.$route.query.checkType?this.$route.query.checkType:''
    }
  },
  mounted() {
  }
}
</script>
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }
  /deep/.show {
    height: auto !important;
  }
  .content {
    min-height: calc(100vh - 40px);
    width: 1060px;
    margin: 20px auto;
    padding: 20px;
    background:#fff;
  }
</style>